/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SharedLibs from 'wizarding-world-web-shared';
import FocusLock from 'react-focus-lock';
import cx from 'classnames';
import { useWindowWidth } from '@hooks/windowHooks';
import { breakpoints } from '@hooks/useBreakpoints';
import { trackClickEvent } from '@utils/analytics';
import { CHECK_ISUNDERAGE } from '@utils/graphQueries';
import Button from '@/src/components/Button';
import s from './AgeGateModal.module.scss';
import DecorativeModal from '../DecorativeModal';

const MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const START_YEAR = 1930;

const propTypes = {
  onAgeGateResult: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const AgeGateModal = ({ onAgeGateResult, closeModal }) => {
  const { api: wizardingWorldAPI } = SharedLibs.services;
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [ageGateFailed, setAgeGateFailed] = useState(false);

  const endYear = new Date().getFullYear();
  const years = Array.from({ length: endYear - START_YEAR + 1 }, (v, i) => endYear - i);
  const location = 'Age Gate Modal';
  const width = useWindowWidth();
  const isMobile = width < breakpoints.sm;

  const handleConfirm = async () => {
    setSubmitted(true);
    const birthdate = `${selectedYear}-${selectedMonth}-01`;
    const { data } = await wizardingWorldAPI().query({
      query: CHECK_ISUNDERAGE,
      variables: { birthdate },
    });

    onAgeGateResult(data.underage.isUnderage);

    if (data.underage.isUnderage) {
      setAgeGateFailed(true);
    }
  };

  return (
    <DecorativeModal>
      <FocusLock autoFocus={!isMobile}>
        <div className={cx(s.root)}>
          {ageGateFailed && (
            <div className={s.content}>
              <div className={s.title}>We’re sorry, the shop is not available for some users</div>
              <Button
                label="Close"
                fullWidth
                onClick={() => {
                  trackClickEvent({
                    label: 'Close',
                    destination_url: window.location.pathname,
                    location,
                  });
                  closeModal();
                }}
              />
            </div>
          )}
          {!ageGateFailed && (
            <div className={s.content}>
              <div className={s.title}>Please enter your date of birth to continue</div>
              <div className={s.dobFields}>
                <div className={s.field}>
                  <label htmlFor="month">Month</label>
                  <select id="month" onChange={(e) => setSelectedMonth(e.target.value)}>
                    <option value="">Select</option>
                    {MONTHS &&
                      MONTHS.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                  </select>
                </div>
                <div className={s.field}>
                  <label htmlFor="year">Year</label>
                  <select id="year" onChange={(e) => setSelectedYear(e.target.value)}>
                    <option value="">Select</option>
                    {years &&
                      years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className={s.buttons}>
                <Button
                  label="Confirm"
                  fullWidth
                  loading={submitted}
                  disabled={!selectedMonth || !selectedYear}
                  onClick={() => {
                    trackClickEvent({
                      label: 'Confirm',
                      destination_url: window.location.pathname,
                      location,
                    });
                    handleConfirm();
                  }}
                />
                <Button
                  type="text"
                  label="Cancel"
                  onClick={() => {
                    trackClickEvent({
                      label: 'Cancel',
                      destination_url: window.location.pathname,
                      location,
                    });
                    closeModal();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </FocusLock>
    </DecorativeModal>
  );
};

AgeGateModal.propTypes = propTypes;
export default AgeGateModal;
