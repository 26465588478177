import React from 'react';
import PropTypes from 'prop-types';
import s from './FlourishHeader.module.scss';

const propTypes = {
  headerText: PropTypes.string.isRequired,
  subText: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

const FlourishHeader = ({ headerText, subText, theme = 'dark' }) => {
  return (
    <div className={[s.root, s[theme]].join(' ')}>
      <div className={s.headerTextRoot}>
        <h2>
          <span className={s.headerText}>{headerText}</span>
        </h2>
      </div>
      {subText && <div className={s.subText}>{subText}</div>}
    </div>
  );
};

FlourishHeader.propTypes = propTypes;
export default FlourishHeader;
