/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SharedLibs from 'wizarding-world-web-shared';
import ShopRowSkeleton from '@components/ShopRowSkeleton';
import Bugsnag from '@bugsnag/js';
import { clearShopCart } from '@utils/shop';
import { useInView } from 'react-intersection-observer';
import { SHOPIFY_CART_LOCALSTORAGE_KEY, AGE_GATE_IS_UNDERAGE } from '@constants/index';
import { useUserContext } from '@contexts/user-context';
import useCountry from '@hooks/useCountry';
import { trackEvent, segmentUserId } from '@utils/analytics';
import { STORE_PASSTHROUGH } from '@constants/routes';
import { STORE_URL } from '@utils/graphQueries';
import FlourishHeader from '@components/FlourishHeader';
import AgeGateModal from '@components/AgeGateModal';
import productBg from './imgs/product-bg.svg';
import lock from './imgs/lock.svg';
import s from './ShopRow.module.scss';

const propTypes = {
  shopData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.oneOf(['dark', 'light']).isRequired,
    shopifyItemId: PropTypes.string.isRequired,
  }),
  location: PropTypes.oneOf(['home', 'article']).isRequired,
  pageName: PropTypes.string,
};

const ShopRow = ({
  shopData = {
    title: '',
    description: '',
    theme: 'dark',
  },
  pageName = '',
  location,
}) => {
  const { title, description, shopifyItemId, theme } = shopData;
  const { api: wizardingWorldApi } = SharedLibs.services;
  const { setCookie, getCookie } = SharedLibs.utils.cookie;
  const [checkoutUTMParams, setCheckoutUTMParams] = useState({});
  const [productUTMParams, setProductUTMParams] = useState({});
  const [shopUrl, setShopUrl] = useState('');
  const [multipassToken, setMultipassToken] = useState(null);
  const { loadingProfile, isLoggedIn, profile } = useUserContext();
  const { country, loading: loadingCountry } = useCountry('');

  // Styling
  const productBackgroundImage = theme === 'dark' ? 'none' : `url(${productBg})`;
  const discountedPriceColor = theme === 'dark' ? '#9FA1AD' : '#696B78';
  const lightMagicColor = '#5758FF';
  const darkMagicColor = '#0B163B';

  // Age gate
  const ecomAgeGateEnabled = process.env.REACT_APP_ECOM_AGE_GATE_ENABLED === 'TRUE';
  const [showAgeGateModal, setShowAgeGateModal] = useState(false);
  const [ageGateSubmitted, setAgeGateSubmitted] = useState(false);
  const [shopDisabled, setShopDisabled] = useState(false);
  const ageGateIsUnderage = getCookie(AGE_GATE_IS_UNDERAGE) === 'true';

  const [sectionRef, inView] = useInView({
    threshold: 0.0,
    triggerOnce: true,
  });

  const currency = country === 'US' ? '$' : '£';
  const moneyFormat = `${currency}{{amount}}`;

  const getMultipassToken = async () => {
    try {
      const { data } = await wizardingWorldApi().query({
        query: STORE_URL,
        variables: {
          path: '/',
        },
      });
      // Get the token from the URL
      const urlObject = new URL(data?.storeUrl?.url);
      const token = urlObject.pathname.split('/').pop();
      setMultipassToken(token);
    } catch (e) {
      Bugsnag.notify(e);
    }
  };

  const loadEmbedScript = () => {
    if (window.ShopifyBuy) {
      return;
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = process.env.REACT_APP_SHOP_EMBED_SCRIPT_URL;
    script.type = 'module';
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  useEffect(() => {
    const isCartEmpty = () => {
      const shopifyCartKey =
        process.env.REACT_APP_SHOPIFY_CART_LOCALSTORAGE_KEY || SHOPIFY_CART_LOCALSTORAGE_KEY;
      // eslint-disable-next-line consistent-return
      let cartEmpty = true;
      Object.keys(localStorage).forEach((key) => {
        // Cart localstorage keys end in ".checkoutId"
        if (key.includes(shopifyCartKey)) {
          cartEmpty = false;
        }
      });
      return cartEmpty;
    };

    // eslint-disable-next-line no-nested-ternary
    const underAgeUser = isLoggedIn
      ? profile.underage
      : ecomAgeGateEnabled
      ? ageGateIsUnderage
      : true;
    if (underAgeUser || (isCartEmpty() && !inView)) {
      return;
    }
    const url = `${STORE_PASSTHROUGH}&utm_source=wwd_web&utm_medium=${location}_module&utm_campaign=HPShop&utm_referrer_path=${window.location.pathname}`;
    setShopUrl(url);
    setCheckoutUTMParams({
      utm_source: 'wwd_web',
      utm_medium: 'shop_carousel',
      utm_campaign: 'HPShop',
      utm_referrer_path: window.location.pathname,
      ajs_uid: segmentUserId(),
    });
    const productUtmMedium = location === 'home' ? 'shop_carousel' : 'article_shop_carousel';
    setProductUTMParams({
      utm_medium: productUtmMedium,
    });
    getMultipassToken();
    loadEmbedScript();
  }, [isLoggedIn, profile, location, inView]);

  useEffect(() => {
    if (ecomAgeGateEnabled) {
      const ageGateCookie = getCookie(AGE_GATE_IS_UNDERAGE);
      // Anon user, no age gate cookie or if user is underage
      if (
        (!isLoggedIn && (!ageGateCookie || ageGateCookie === 'true')) ||
        (isLoggedIn && profile.underage)
      ) {
        clearShopCart();
      }
      setAgeGateSubmitted(!!ageGateCookie);
    }
  }, [isLoggedIn]);

  const cssVars = {
    '--buy-button-component-text-font-family': 'Harry Beast Regular',
    '--buy-button-component-button-hover-background-color': lightMagicColor,
    '--buy-button-component-cart-button-background-color': lightMagicColor,
    '--buy-button-component-cart-button-hover-background-color': darkMagicColor,
    '--buy-button-component-button-font-family': 'Sofia Pro',
    '--buy-button-component-header-font-family': 'Sofia Pro',
    '--buy-button-component-price-font-family': 'Sofia Pro',
    '--buy-button-component-toggle-button-background-color': lightMagicColor,
    '--buy-button-component-product-card-width': '208px',
    '--buy-button-component-card-background': theme === 'dark' ? '#1C1E28' : 'transparent',
    '--buy-button-component-image-wrapper-background': theme === 'dark' ? '#242633' : 'transparent',
    '--buy-button-component-text-color': theme === 'dark' ? '#FFF' : '#10141B',
    '--buy-button-component-button-color': '#FFF',
    '--buy-button-component-button-color-hover': '#FFF',
    '--buy-button-component-carousel-actions-color': theme === 'dark' ? '#FFF' : '#10141B',
    '--buy-button-component-carousel-actions-border':
      theme === 'dark' ? 'thin solid white' : 'thin solid #10141B',
    '--buy-button-component-carousel-actions-hover-border-color':
      theme === 'dark' ? 'white' : '#10141B',
    '--buy-button-component-carousel-actions-hover-color': theme === 'dark' ? '#FFF' : '#10141B',
    '--buy-button-component-carousel-actions-hover-color-mobile':
      theme === 'dark' ? '#FFF' : '#10141B',
    '--buy-button-component-cart-text-color': '#FFF',
    '--buy-button-component-scroller-color': 'transparent',
    '--buy-button-component-text-accent-color': '#9FA1AD',
  };

  const customCSS = `
  @font-face {
    font-family: 'Harry Beast Regular';
    src: url('/fonts/harry-beast/HarryBeastStandard-Regular.woff2') format('woff2'),
      url('/fonts/harry-beast/HarryBeastStandard-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Harry Beast Display';
    src: url('/fonts/harry-beast/HarryBeastDisplay-Regular.woff2') format('woff2'),
      url('/fonts/harry-beast/HarryBeastDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sofia Pro';
    src: url('/fonts/sofia-pro/normal_normal_300.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Sofia Pro';
    src: url('/fonts/sofia-pro/normal_normal_400.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  .shopify-buy__btn  {
    background: ${lightMagicColor};
    border: 1px solid ${lightMagicColor};
    font-size: 12px;
    @media (max-width: 600px) {
      width: 230px;
      height: 48px;
    }
  }
  .shopify-buy__btn:hover  {
    background: ${darkMagicColor};
    border: 1px solid ${lightMagicColor};
  }
  .shopify-buy__btn[disabled] {
    background: rgba(87, 88, 255, 0.5);
    border: 1px solid rgba(87, 88, 255, 0.5);
    opacity: 0.4;
  }
  .shopify-buy__product {
    margin-left: 33px;
  }
  .shopify-buy__product .shopify-buy__product__title {
    font-size: 14px;
    font-weight: 400;
  }
  .shopify-buy__product .shopify-buy__product__actual-price {
    font-size: 11px;
  }
  .shopify-buy__product__compare-price {
    font-family: 'Sofia Pro';
    font-size: 11px !important;
    color: ${discountedPriceColor} !important;
  }
  .shopify-buy__option-select__select {
    font-size: 14px;
    font-family: 'Sofia Pro';
    padding: 2px 10px;
  }
  .shopify-buy__cart__title {
    font-family: 'Harry Beast Regular';
    font-size: 24px;
    color: #ffffff;
  }
  .shopify-buy__quantity-decrement,
  .shopify-buy__quantity,
  .shopify-buy__quantity-increment {
    background: #1C1E28;
  }
  .shopify-buy__quantity-decrement:hover,
  .shopify-buy__quantity:hover,
  .shopify-buy__quantity-increment:hover {
    background: #10141B;
    border-color: #6b63b5;
  }
  .shopify-buy__product .shopify-buy__product-img-wrapper {
    background-image: ${productBackgroundImage};
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 208px;
    @media (max-width: 576px) {
      height: 360px;
    }
  }
  .shopify-buy__cart-item__full-price,
  .shopify-buy__cart-item__discount,
  .shopify-buy__cart-empty-text  {
    color: #FFF;
  }
  .shopify-buy__option-select-wrapper {
    background: transparent;
    max-width: 66px;
  }
  .shopify-buy__cart-scroll {
    @media (max-width: 576px) {
      padding: 70px 0 90px 0;
    }
  }
  // Cart mobile styles
  .shopify-buy__quantity-container {
    @media (max-width: 576px) {
      float: left;
    }
  }
  .shopify-buy__cart {
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 576px)  {
    .shopify-buy-frame--cart {
      max-width: unset !important;
    }
  }
  .shopify-buy__collection-products {
    scrollbar-width: none;
  }
  .shopify-buy__collection-products::-webkit-scrollbar {
    display: none;
  }
  `;

  const overrideConfig = {
    options: {
      cart: {
        popup: true,
      },
      window: {
        width: 1000,
        height: 1000,
      },
    },
  };

  const handleInvisibleOverlayClick = () => {
    setShowAgeGateModal(true);
  };

  const onAgeGateResult = (underage) => {
    setAgeGateSubmitted(true);
    setCookie({
      name: AGE_GATE_IS_UNDERAGE,
      value: !!underage,
      expires: 48 * 60 * 60 * 1000,
    });
    if (underage) {
      setShopDisabled(true);
    } else {
      setShowAgeGateModal(false);
    }
  };

  const closeModal = () => {
    setShowAgeGateModal(false);
  };

  if (loadingProfile || loadingCountry) {
    return <ShopRowSkeleton type="multiple" theme={theme} shopData={shopData} shopLink />;
  }

  return (
    <div className={cx(s.shopRow, s[theme], shopDisabled ? s.disabled : '')} ref={sectionRef}>
      {showAgeGateModal && (
        <AgeGateModal onAgeGateResult={onAgeGateResult} closeModal={closeModal} />
      )}
      <div className={s.contentWrapper}>
        <div className={s.header}>
          {title && <FlourishHeader headerText={title} subText={description} theme={theme} />}
        </div>
        <div className={s.invisibleOverlayWrapper}>
          <buy-button-component
            tabIndex={shopDisabled || (!isLoggedIn && !ageGateSubmitted) ? '-1' : undefined}
            idx="hpshop-carousel"
            multipassToken={multipassToken}
            productPath="/products/"
            collectionHandle={shopifyItemId}
            cssVars={JSON.stringify(cssVars)}
            customCSS={customCSS}
            moneyFormat={moneyFormat}
            checkoutUTMParams={JSON.stringify(checkoutUTMParams)}
            productUTMParamsOverride={JSON.stringify(productUTMParams)}
            overrideConfig={JSON.stringify(overrideConfig)}
          />
          <div className={cx(s.shopLink)}>
            <a
              href={shopUrl}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={shopDisabled || (!isLoggedIn && !ageGateSubmitted) ? '-1' : undefined}
              onClick={() => {
                trackEvent('CTA Clicked', {
                  cta_text: 'Visit the Harry Potter Shop',
                  location: pageName,
                });
              }}
            >
              Visit the Harry Potter Shop
            </a>
          </div>
          <div
            className={cx(
              s.invisibleOverlay,
              !ecomAgeGateEnabled || ageGateSubmitted || (isLoggedIn && !profile.underage)
                ? s.disabled
                : '',
            )}
            tabIndex={ecomAgeGateEnabled && !isLoggedIn && !ageGateSubmitted ? '0' : undefined}
            data-testid="invisibleOverlay"
            onClick={handleInvisibleOverlayClick}
            onKeyDown={(e) => {
              /* istanbul ignore else */
              if (e.key === 'Enter') {
                handleInvisibleOverlayClick();
              }
            }}
          />
        </div>
      </div>
      {shopDisabled && (
        <div className={s.disabledOverlay} data-testid="disabledOverlay">
          <div className={s.shopUnavailable}>
            <img src={lock} alt="lock" />
            <div>We’re sorry, the shop is not available for some users</div>
          </div>
        </div>
      )}
    </div>
  );
};

ShopRow.propTypes = propTypes;
export default ShopRow;
