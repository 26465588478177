import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import cornerArrow from './imgs/corner-arrow.svg';
import s from './DecorativeModal.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const DecorativeModal = ({ children }) => {
  return (
    <div className={s.overlay}>
      <div className={s.modal}>
        <div className={s.container}>
          <img src={cornerArrow} className={cx(s.corner, s.topLeft)} alt="top left" />
          <img src={cornerArrow} className={cx(s.corner, s.topRight)} alt="top right" />
          <img src={cornerArrow} className={cx(s.corner, s.bottomLeft)} alt="bottom left" />
          <img src={cornerArrow} className={cx(s.corner, s.bottomRight)} alt="top right" />
          <div className={cx(s.content)}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DecorativeModal.propTypes = propTypes;
export default DecorativeModal;
