import { SHOPIFY_CART_LOCALSTORAGE_KEY } from '@/src/constants';

export const clearShopCart = () => {
  const shopifyCartKey =
    process.env.REACT_APP_SHOPIFY_CART_LOCALSTORAGE_KEY || SHOPIFY_CART_LOCALSTORAGE_KEY;
  // Clears the shopify cart
  Object.keys(localStorage).forEach((key) => {
    // Cart localstorage keys end in ".checkoutId"
    if (key.includes(shopifyCartKey)) {
      localStorage.removeItem(key);
    }
  });
};

export const removeCartToggleElement = () => {
  clearShopCart();
  // Remove the cart toggle html element.
  // This is needed because clearing the cart doesn't remove the element
  const cartElement = document.getElementsByClassName('shopify-buy-frame--toggle')[0];
  // istanbul ignore else
  if (cartElement) {
    cartElement.remove();
  }
};

export default { clearShopCart, removeCartToggleElement };
